import React from "react";
import ReactDOM from "react-dom";
import { locale } from "../../app";

export const Venue = ({ venue }) => {
    if (!venue) {
        return <React.Fragment />;
    }

    return (
        <section id="venue-description" className="row">
            <h3>{venue.name}</h3>
            {window.displayCompleteSchedule && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: venue.custom_fields["description-" + locale],
                    }}
                />
            )}
        </section>
    );
};
