export const boundQuantity = (x) => Math.max(1, Math.min(x, 20));

// source: https://stackoverflow.com/a/38061142
export const replaceInArray = (xs, i, val) => {
  if (i < xs.length) {
    return [...xs.slice(0, i), val, ...xs.slice(i + 1)];
  } else {
    return [...xs, ...Array(i - xs.length), val];
  }
};
