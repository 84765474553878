// Brunch automatically concatenates all files in your
// watched paths. Those paths can be configured at
// config.paths.watched in "brunch-config.js".
//
// However, those files will only be executed if
// explicitly imported. The only exception are files
// in vendor, which are never wrapped in imports and
// therefore are always executed.

// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".
import "../css/app.scss";
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://416fd9bd91854f6591d5e894374209e2@sentry.hawaii.do/22",
});

const bowser = require("bowser");
const browser = bowser.getParser(window.navigator.userAgent);

const isChrome = bowser.getParser(window.navigator.userAgent);

console.log(`The current browser name is "${isChrome.getBrowserName()}"`);

if (browser.getBrowserName() === "Internet Explorer") {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("msie");
}
if (isChrome.getBrowserName() === "Chrome") {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("chrome");
}
if (isChrome.getBrowserName() === "Firefox") {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("firefox");
}

import "./components/map";

import "./components/stickySidebar";

import "babel-polyfill";
import "url-search-params-polyfill";

// import StickySidebar from "sticky-sidebar";

import React from "react";
import ReactDOM from "react-dom";

import "phoenix_html";
import i18next from "i18next";
import objectFitImages from "object-fit-images";
import Swiper from "swiper";

import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en";
import de from "date-fns/locale/de";

import { Basket } from "./components/Basket";
import { EventWidget } from "./components/EventWidget";
import { BasketPage } from "./components/BasketPage";
import { ArtistMedias } from "./components/ArtistMedias";

import { CookieAlert } from "./components/matomo/cookieAlert";

// Import local files
//
// Local files can be imported directly using relative
// paths "./socket" or full ones "web/static/js/socket".

//import socket from "./socket";
import * as Schedule from "./components/Schedule";
import { Profile } from "./components/Profile";

import fr_default from "./locales/fr/default.json";
import fr_basket from "./locales/fr/basket.json";
import fr_payment from "./locales/fr/payment.json";
import fr_schedule from "./locales/fr/schedule.json";
import fr_user from "./locales/fr/user.json";
import fr_layout from "./locales/fr/layout.json";

import en_default from "./locales/en/default.json";
import en_basket from "./locales/en/basket.json";
import en_payment from "./locales/en/payment.json";
import en_schedule from "./locales/en/schedule.json";
import en_user from "./locales/en/user.json";
import en_layout from "./locales/en/layout.json";

import de_default from "./locales/de/default.json";
import de_basket from "./locales/de/basket.json";
import de_payment from "./locales/de/payment.json";
import de_schedule from "./locales/de/schedule.json";
import de_user from "./locales/de/user.json";
import de_layout from "./locales/de/layout.json";

import "intersection-observer";
import lozad from "lozad";

const observer = lozad(".lozad", {
    loaded: function (el) {
        el.classList.remove("hidden");
    },
});
observer.observe();

export const locale = window.siteLocale;
const dateLocales = {
    fr,
    en,
    de,
};

export const dateLocale = dateLocales[locale];

const initialLocales = {
    fr: {
        default: fr_default,
        basket: fr_basket,
        payment: fr_payment,
        schedule: fr_schedule,
        user: fr_user,
        layout: fr_layout,
    },
    en: {
        default: en_default,
        basket: en_basket,
        payment: en_payment,
        schedule: en_schedule,
        user: en_user,
        layout: en_layout,
    },
    de: {
        default: de_default,
        basket: de_basket,
        payment: de_payment,
        schedule: de_schedule,
        user: de_user,
        layout: de_layout,
    },
};

i18next.init({
    debug: false, // TODO must be false in production
    lng: locale,
    returnEmptyString: false,
    keySeparator: "😄",
    nsSeparator: "😄",
    parseMissingKeyHandler: (key) => key,
    resources: initialLocales,
});

const cookieAlert = document.getElementById("cookie-alert");
if (cookieAlert) {
    ReactDOM.render(
        <CookieAlert link={cookieAlert.dataset.link} />,
        cookieAlert,
    );
}

const basket = document.getElementById("basket");
if (basket) {
    ReactDOM.render(<Basket basketPath={window.basketPath} />, basket);
}

const widgetEventZone = document.getElementById("widget-event");

if (widgetEventZone) ReactDOM.render(<EventWidget />, widgetEventZone);

const eventSchedule = document.getElementById("event-schedule-component");
if (eventSchedule) Schedule.renderWithData(eventSchedule);

const basketPage = document.getElementById("basket-page");
if (basketPage) {
    ReactDOM.render(
        <BasketPage settings={window["CullyJazzBasketPageConfig"]} />,
        basketPage,
    );
}

const customerProfile = document.getElementById("customer-profile-component");
if (customerProfile) {
    ReactDOM.render(<Profile />, customerProfile);
}

const artistMedias = document.querySelectorAll(".artist-medias");
[].forEach.call(artistMedias, (zone) => {
    ReactDOM.render(<ArtistMedias path={$(zone).data("path")} />, zone);
});

// show/hide navigation panels

let giftCouponValues = document.querySelectorAll(
    'input[name="gift_coupon[gift_coupon_value]"]',
);
if (giftCouponValues.length > 0) {
    let customValue = document.querySelector("#custom_value");
    for (let index = 0; index < giftCouponValues.length; index++) {
        giftCouponValues[index].onclick = function (e) {
            customValue.style.display =
                e.target.value == "custom" ? "block" : "none";
        };
    }
}

var wallopElement = document.querySelector(".Wallop");
if (wallopElement) {
    var wallop = new Wallop(wallopElement);
    if (document.querySelectorAll(".Wallop-item").length > 1) {
        setInterval(() => wallop.next(), 8000);
    }
}

$(document).ready(function () {
    var body = $("body"),
        fullPagePanel = $(".full-page-panel"),
        panelPratique = $("#nav-pratique"),
        panelMenu = $("#nav-menu"),
        panelPratiqueTrigger = $("#main-nav ul li:nth-child(2)"),
        panelMenuTrigger = $("#main-nav ul li:nth-child(3)");

    var isHomepage = $(".is_homepage").length > 0;

    panelPratiqueTrigger.on("click", function () {
        panelPratiqueTrigger.toggleClass("active");
        panelMenuTrigger.removeClass("active");
        if (panelMenu.hasClass("full-page-panel--open")) {
            panelPratique.toggleClass("full-page-panel--open");
            panelMenu.removeClass("full-page-panel--open");
        } else {
            panelPratique.toggleClass("full-page-panel--open");
            body.toggleClass("panel_open");
            body.removeClass("nav_small");
        }
    });

    panelMenuTrigger.on("click", function () {
        panelMenuTrigger.toggleClass("active");
        panelPratiqueTrigger.removeClass("active");
        if (panelPratique.hasClass("full-page-panel--open")) {
            panelMenu.toggleClass("full-page-panel--open");
            panelPratique.removeClass("full-page-panel--open");
        } else {
            panelMenu.toggleClass("full-page-panel--open");
            body.toggleClass("panel_open");
            body.removeClass("nav_small");
        }
    });

    // homepage fixed nav turn into black letter on scroll

    // get document coordinates of the element
    function getCoords(elem) {
        let box = elem.getBoundingClientRect();

        return {
            top: box.top + pageXOffset,
            right: box.right + pageXOffset,
            left: box.left + pageXOffset,
            bottom: box.bottom + pageXOffset,
        };
    }

    // debounce scroll --> use it ?
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    var scrollHandler = debounce(function () {
        var frontElementBottom = getCoords($(".homepage-front-gallery")[0]);
        var currentPosition = $(window).scrollTop();

        if (currentPosition > frontElementBottom.bottom) {
            body.addClass("nav_black");
            body.removeClass("nav_opacity");
        } else if (
            currentPosition > 50 &&
            frontElementBottom.bottom > currentPosition
        ) {
            body.addClass("nav_small");
            body.addClass("nav_opacity");
            body.removeClass("nav_black");
        } else {
            body.removeClass("nav_black");
            body.removeClass("nav_small");
            body.removeClass("nav_opacity");
        }
    });

    // header get smaller on scroll

    var scrollHeader = debounce(function () {
        var scrollPos = $(window).scrollTop();
        if (scrollPos > 130) {
            body.addClass("nav_small");
        } else {
            body.removeClass("nav_small");
        }
    });

    if (isHomepage) {
        $(window).on("scroll", function (e) {
            window.requestAnimationFrame(scrollHandler);
        });
    } else {
        $(window).on("scroll", function (e) {
            window.requestAnimationFrame(scrollHeader);
        });
    }

    // Smooth scroll to anchors

    $(".artist:eq(0) .artist-name").on("click", function (event) {
        event.preventDefault();

        $("html, body").animate(
            {
                scrollTop: 0,
            },
            300,
        );
    });
    $(".artist")
        .not(":eq(0)")
        .find(".artist-name")
        .on("click", function (event) {
            event.preventDefault();
            let customScroll = $($.attr(this, "href")).offset().top;

            $("html, body").animate(
                {
                    scrollTop: customScroll - 120,
                },
                300,
            );
        });

    // Swiper

    const slides = document.querySelectorAll(
        ".homepage-front-gallery .swiper-slide",
    );

    if (slides.length > 1) {
        new Swiper(".homepage-front-gallery", {
            speed: 600,
            loop: false,
            spaceBetween: 10,
            autoplay: {
                delay: 8000,
            },
            slidesPerView: 1,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
                loadOnTransitionStart: true,
            },
            breakpoints: {
                500: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    effect: "fade",
                    fadeEffect: {
                        crossFade: true,
                    },
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 1,
                    },
                },
            },
        });
    }

    // Pratique panel change layout if < 4 items

    var navItem = $("#nav-pratique > a");

    if (navItem.length <= 4) {
        navItem.addClass("big-item-pratique");
    } else {
        navItem.removeClass("big-item-pratique");
    }

    $("form").on("change", "#ticket-selection-women", function (e) {
        let show = $(this).val();
        if (show !== "") {
            let value = $("#womens_day_women_ticket").val();
            if (value !== "") value += "\n";
            value += `1x Billet Women’s Day : ${show}`;
            $("#womens_day_women_ticket").val(value);
            $(this).val("");
        }
    });

    $("form").on("change", "#show-selection", function (e) {
        let show = $(this).val();
        if (show !== "") {
            let value = $("#womens_day_ticket").val();
            if (value !== "") value += "\n";
            value += `x Billet(s) : ${show}`;
            $("#womens_day_ticket").val(value);
            $(this).val("");
        }
    });
});
