import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();

import parse from "date-fns/parse";
import format from "date-fns/format";
import compareAsc from "date-fns/compare_asc";
import { locale } from "../app";
import { Filters } from "./schedule/Filters";
import { List } from "./schedule/List";
import { ShrinkedList } from "./schedule/List";
import { Venue } from "./schedule/Venue";
import store from "store";
import i18next from "i18next";

/**
 * Render the Schedule program component to select interactively filtering criteria.
 *
 * We only render it if we have data to avoid replacing user content with nothing
 * because of the timing of requests
 * @param domElement Where to render the component
 */
export const renderWithData = (domElement) => {

    const { eventsPath, showPath } = window["CullyJazzScheduleConfig"];

    const url = domElement.dataset.url;

    jQuery.getJSON(`${eventsPath}?${Date.now()}`).done((data) => {
        // TODO disabled while we did not write the rendering here
        ReactDOM.render(
            <Router history={browserHistory}>
                <Route
                    path={url}
                    component={(props) => <Schedule 
                        filterEnable={JSON.parse(domElement.dataset.filterEnable)}
                        filterLimited={JSON.parse(domElement.dataset.filterLimited)}
                        categoryOffEnable={JSON.parse(domElement.dataset.categoryOffEnable)}
                        showPath={showPath} 
                        url={url}
                        {...data}
                        {...props} />
                    }
                    history={browserHistory}
                />
            </Router>,
            domElement
        );
    });
};

export class Schedule extends React.Component {
    constructor(props, context) {
        super(props, context);
        const state = {
            // all events from the server
            events: [],
            // remaining events after we applied the user filters
            filteredEvents: [],
            // store dynamic filters (places, dates) provided by the server
            filters: {
                categories: [], // FilterItem{ key: String, localized_name: String }
                venues: [],
                dates: [],
            },
            venues: {}, // Map[Id, Venue]
            categories: {},
        };

        const { venues, categories } = props.filters;
       
        venues.forEach((value) => (state.venues[value.id] = value));
        categories.forEach((value, index) => (state.categories[value] = index));
        
        // Enrich the events object received from the backend
        // It allows to avoid nested object passing to resolve
        // foreign entities or computing date format every time
        state.events = props.events.map((event) => {
            
            event.parsed_start_date = parse(event.start_date);
            event.day = format(event.parsed_start_date, "YYYY-MM-DD");

            event.venueName = (() => {
                const venue = state.venues[event.venue_id];
                if (venue) {
                    switch (locale) {
                        case "fr":
                            return venue.name;
                        case "en":
                            return venue.name;
                        case "de":
                            return venue.name;
                    }
                }
            })();

            return event;
        });

        state.filters = props.filters;
        this.state = state;
    }

    initDay() {

        const params = this.getParamsFromUrl();
        const { dates } = this.state.filters;

        if (params.date || params.venue) {
            return;
        }

        let now = new Date();

        now.setHours(now.getHours() - 4); // change day at 4h (end of concerts)
        const now_format = format(now, "YYYY-MM-DD");

        if (dates.indexOf(now_format) === -1) {
            return;
        }

        this.updateParam("date", now_format);
        this.updateParam("in", true);
        this.updateParam("off", true);
        this.updateParam("jazz", true);
    }

    getParamsFromUrl() {
        const { location } = this.props.history;
        const searchParams = new URLSearchParams(location.search);

        const categories = searchParams.get("categories") || "";
        return {
            venue: searchParams.get("venue") || "",
            date: searchParams.get("date") || "",
            categories: {
                in: categories.includes("in") || categories === "",
                off: categories.includes("off"),
                jazz: categories.includes("autour-du-jazz"),
            },
        };
    }

    generateSearchParams(params) {
        const searchParams = new URLSearchParams();

        if (params.venue) {
            searchParams.set("venue", params.venue);
        }

        if (params.date) {
            searchParams.set("date", params.date);
        }

        let categories = [];
        if (params.categories.in) {
            categories.push("in");
        }
        if (params.categories.off) {
            categories.push("off");
        }
        if (params.categories.jazz) {
            categories.push("autour-du-jazz");
        }

        if (categories.length) {
            categories = categories.join("-");
            if (categories !== "in") {
                searchParams.set("categories", categories);
            }
        }

        return searchParams.toString();
    }

    updateURL = (params) =>
        this.props.history.push(`${this.props.url}?${params}`);

    compareDates(a, b) {
        // if start_date is between 00:00 and 01:00, add 23h to sort it after 23:00
        // Parse the date first
        let start_date_a = parse(a.start_date, "yyyy-MM-dd HH:mm:ss", new Date());
        let start_date_b = parse(b.start_date, "yyyy-MM-dd HH:mm:ss", new Date());
        if (start_date_a.getHours() === 0) {
            a.sort_date =start_date_a.setTime(start_date_a.getTime() + 23 * 60 * 60 * 1000);
        } else {
            a.sort_date = a.start_date;
        }
        if (start_date_b.getHours() === 0 ) {
            b.sort_date = start_date_b.setTime(start_date_b.getTime() + 23 * 60 * 60 * 1000);
        } else {
            b.sort_date = b.start_date;
        }

        return compareAsc(a.sort_date, b.sort_date);

    }

    updateParam(field, value) {
        const { filterLimited } = this.props;
        const params = this.getParamsFromUrl();

        if (["in", "off", "jazz"].indexOf(field) !== -1) {
            params.categories[field] = value;
            params["venue"] = null;
        } else {
            params[field] = value;
        }

        // change categories to match venue
        if (!filterLimited && field === "venue" && value !== "") {
            params.categories = {
                in: this.state.venues[value].tags.indexOf("IN") !== -1,
                off: this.state.venues[value].tags.indexOf("OFF") !== -1,
                jazz:
                    this.state.venues[value].tags.indexOf("Autour du jazz") !==
                    -1,
            };
        }
        this.updateURL(this.generateSearchParams(params));
    }

    __groupEvents = (filteredEvents) => {
        const { venues } = this.props.filters;
        const venue_ids = venues.map((v) => v.id);
        const eventsPerDay = {};
        filteredEvents.forEach((event) => {
            const { day } = event;
            if (!eventsPerDay.hasOwnProperty(day)) {
                eventsPerDay[day] = { paid: [], free: [] };
            }
            eventsPerDay[event.day][
                event.price === 0 || event.price === null || typeof(event.price) == 'undefined'  ? "free" : "paid"
            ].push(event);
        });

        const eventSort = (a, b) => {
            let { categories } = this.props.filters;

            const compare = (a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            };

            // 1 sort by categories (IN, OFF, Autour du Jazz)
            categories = categories.map((category) => category.toLowerCase());

            const categorySort = compare(
                categories.indexOf(a.category.toLowerCase()),
                categories.indexOf(b.category.toLowerCase())
            );

            if (categorySort !== 0) {
                return categorySort;
            }

            const aVenueIndex = venue_ids.indexOf(a.venue_id);
            const bVenueIndex = venue_ids.indexOf(b.venue_id);
            let venueSort = 0;
            if (
                a.category.toLowerCase() !== "off" &&
                a.category.toLowerCase() !== "autour du jazz"
            ) {
                venueSort = compare(aVenueIndex, bVenueIndex);
            } else {
     

                let dateCompare = this.compareDates(a, b);
                if (dateCompare === 0) {
                    return compare(
                        venues[aVenueIndex].name,
                        venues[bVenueIndex].name
                    );
                } else {
                    return dateCompare;
                }
            }

            if (venueSort === 0) {
                return this.compareDates(a, b);
            } else {
                return venueSort;
            }
        };

        Object.keys(eventsPerDay).forEach((date) => {
            eventsPerDay[date].free.sort((a, b) => eventSort(a, b));
            eventsPerDay[date].paid.sort((a, b) => eventSort(a, b));
        });

        return eventsPerDay;
    };

    __filterEvents = (events, params) => {
        const criteriaToEventField = {
            categories: "tags",
            venue: "venue_id",
            date: "day",
        };
        const criteriaCondition = {
            venue: (_event, value, choice) =>
                choice === "" || value === parseInt(choice),
            date: (event, value, choice) => choice === "" || value === choice || event.id_marcato === 8535, // 8535 is for event balade that display every day
            categories: (event, value, choice) => {
                if (choice.in && value.indexOf("IN") !== -1) return true;
                if (choice.off && value.indexOf("OFF") !== -1) return true;
                if (choice.jazz && value.indexOf("Autour du jazz") !== -1)
                    return true;
                return false;
            },
        };


        let filtered = events.filter((event) => {
            // An event should match with every criteria
            return Object.keys(params).every((criteria) => {
                if (!criteriaCondition[criteria]) return true;
                const value = event[criteriaToEventField[criteria]];
                
                let ans =  criteriaCondition[criteria](event, value, params[criteria]);
                return ans;
            });
        });
        return filtered;
    };

    filterAndGroupsEvents = (events, params) => {
        return this.__groupEvents(this.__filterEvents(events, params));
    };

    showPath = ({ id_airtable, slug }) =>
        this.props.showPath.replace("ID", id_airtable).replace("SLUG", slug);

    render() {
        this.initDay();
       
        const { filterEnable, filterLimited, categoryOffEnable } = this.props;
        
        const { filters, venues, events } = this.state;

        const params = this.getParamsFromUrl();
        const searchParams = this.generateSearchParams(params);

        const filteredEvents = this.filterAndGroupsEvents(events, params);

        const list = filterEnable ? 
            <List
                eventsPerDay={filteredEvents}
                dates={filters.dates}
                showPath={this.showPath}
                filterLimited={filterLimited}
            />
         : <ShrinkedList
            eventsPerDay={filteredEvents}
            dates={filters.dates}
            showPath={this.showPath}
            filterLimited={filterLimited}
        />;


        return (
            <React.Fragment>
                {filterEnable && (
                    <Filters
                        params={params}
                        updateParam={(field, value) =>
                            this.updateParam(field, value)
                        }
                        dates={filters.dates}
                        venues={filters.venues}
                        url={this.props.url}
                        searchParams={searchParams}
                        filterLimited={filterLimited}
                        categoryOffEnable={categoryOffEnable}
                    />
                )}

                <Venue key={params.venue} venue={venues[params.venue]} />
                {list}
            </React.Fragment>
        );
    }
}
