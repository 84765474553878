import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { Loader } from "./Loader";

import { boundQuantity } from "../shared/utils";

const t = (key) => i18next.t(key, { ns: "basket" });

export class EventWidget extends React.Component {
    constructor(props, context) {
        super(props, context);

        const {
            eventId,
            schedulePath,
            eventPath,
            addToBasketPath,
            basketPath,
            csrfToken,
        } = window["CullyJazzEventWidgetConfig"];

        this.state = {
            isLoading: false,
            title: undefined,
            isFull: false,
            price: undefined,
            categoryId: undefined,
            zoneTitle: undefined,
            eventId,
            schedulePath,
            eventPath,
            basketPath,
            addToBasketPath,
            csrfToken,
            seats: 1,
            freeSeats: undefined,
            hasData: false,
            submitted: false,
        };
    }

    __fetchEventInformation() {
        this.setState({ isLoading: true }, () => {
            const { eventId, eventPath } = this.state;
            if (eventId) {
                return jQuery
                    .getJSON(eventPath)
                    .done((data) => {
                        //console.log("[XHR] data", data);
                        const {
                            ticket_name: title,
                            price_name: zoneTitle,
                            free_seats: freeSeats,
                            category_id: categoryId,
                            is_full: isFull,
                            price,
                        } = data;

                        this.setState({
                            isLoading: false,
                            hasData: true,
                            title,
                            zoneTitle,
                            freeSeats,
                            categoryId,
                            isFull,
                            price,
                        });
                    })
                    .fail((err) => {
                        console.log("[XHR] Fail B", eventId, eventPath);
                        this.setState({ isLoading: false, error: true });
                        this.renderErrorBox();
                    });
            }
        });
    }

    __addToBasket(e) {
        const { addToBasketPath } = this.state;
        const { seats, categoryId } = this.state;

        // prevent default
        e.preventDefault();

        this.setState({ isLoading: true, submitted: true }, () => {
            const { eventId, eventPath, csrfToken } = this.state;

            const data = {
                category_id: categoryId,
                seats: seats,
                _csrf_token: csrfToken,
            };

            return jQuery
                .post(addToBasketPath, data)
                .done((data) => {
                    //console.log("[XHR] Success", data);
                    this.setState({ isLoading: false });
                    this.renderSuccessBox();
                })
                .fail((err) => {
                    console.log("[XHR] Fail A", err);
                    this.setState({ isLoading: false });
                    this.renderErrorBox();
                });
        });
    }

    componentDidMount() {
        this.__fetchEventInformation();
    }

    renderSuccessBox() {
        jQuery(".alert").remove();
        jQuery("<p/>", {
            class: "alert alert-success",
            text: i18next.t("Le billet a bien été ajouté.", {
                ns: "basket",
                count: this.state.seats,
            }),
        }).prependTo("#container");
    }

    renderErrorBox() {
        jQuery(".alert").remove();
        jQuery("<p/>", {
            class: "alert alert-danger",
            text: t("Une erreur s'est produite, veuillez réessayer."),
        }).prependTo("#container");
    }

    renderContent() {
        const { seats, isLoading, isFull } = this.state;
        const seatOptions = [...Array(9).keys()].map((x) => x + 1);
        const { title, zoneTitle, price, schedulePath } = this.state;
        const { submitted } = this.state;
        const { basketPath } = this.state;

        const offsetSeats = (offset) =>
            this.setState({ seats: boundQuantity(this.state.seats + offset) });

        const updatePrice = (e) =>
            this.setState({ seats: boundQuantity(parseInt(e.target.value)) });

        if (isFull) {
            return (
                <div>
                    {/* <span className="entitled">{title}</span> */}
                    <div className="soldout">{t("Complet")}</div>
                </div>
            );
        }

        return (


                <div className="price-select">
                    <div className="input-number-wrapper">
                        <span
                            className="input-group-btn"
                            onClick={() => (isLoading ? null : offsetSeats(-1))}
                        >
                            <button
                                type="button"
                                className="btn-number"
                                disabled={isLoading}
                            >
                                <span className="minus" />
                            </button>
                        </span>
                        <input
                            type="number"
                            className="form-control input-number"
                            onInput={(e) => updatePrice(e)}
                            value={seats}
                        />
                        <span
                            className="input-group-btn"
                            onClick={() => (isLoading ? null : offsetSeats(1))}
                        >
                            <button
                                type="button"
                                className="btn-number"
                                disabled={isLoading}
                            >
                                <span className="minus plus" />
                            </button>
                        </span>
                    </div>
                    <button
                        type="button"
                        className="btn-std"
                        disabled={isLoading}
                        onClick={(e) => this.__addToBasket(e)}
                    >
                        {t("Ajouter au panier")}
                    </button>
                </div>

        );
    }

    render() {
        const { isLoading, hasData, eventId } = this.state;
        const debug = false;

        if (!eventId) {
            return <span />;
        }

        return (
            <div className="card">
                {hasData ? this.renderContent() : undefined}

                {debug ? (
                    <pre>{JSON.stringify(this.state, null, 2)}</pre>
                ) : undefined}
            </div>
        );
    }
}
