import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

const t = (key) => i18next.t(key, { ns: "user" });

export class PasswordForm extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            password: "",
            password_bis: "",
        };
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = {
            password: this.state.password,
            password_bis: this.state.password_bis,
        };

        this.props.submitForm(data);
        this.setState({
            password: "",
            password_bis: "",
        });
    }

    updateForm(key, value) {
        this.setState({ [key]: value });
    }

    render() {
        return (
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <h2>{t("Modifier votre mot de passe")}</h2>
                <div className="form-group">
                    <label for="update_password">{t("Mot de passe")}</label>
                    <input
                        className="form-control"
                        id="update_password"
                        name="update[password]"
                        value={this.state.password}
                        type="password"
                        onChange={(e) =>
                            this.updateForm("password", e.target.value)
                        }
                    />
                </div>
                <div className="form-group">
                    <label for="update_password_bis">
                        {t("Mot de passe (vérification)")}
                    </label>
                    <input
                        className="form-control"
                        id="update_password_bis"
                        name="update[password_bis]"
                        value={this.state.password_bis}
                        type="password"
                        onChange={(e) =>
                            this.updateForm("password_bis", e.target.value)
                        }
                    />
                </div>
                <button
                    className="btn-std btn-bg-one"
                    type="submit"
                    disabled={this.props.isLoading}
                >
                    {t("Mettre à jour mon mot de passe")}
                </button>
            </form>
        );
    }
}
