import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

import format from "date-fns/format";
import { dateLocale, locale } from "../../app";

const t = (key) => i18next.t(key, { ns: "user" });

export class Orders extends React.Component {
    render() {
        let { orders } = this.props;

        if (!orders) {
            return (
                <div className="basket-item">
                    <div className="basket-item-error">
                        {t(
                            "Impossible de récupérer les commandes, veuillez réessayer plus tard"
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div>
                {orders.map((order) => (
                    <Order order={order} />
                ))}
            </div>
        );
    }
}

export class Order extends React.Component {
    statusClass(status) {
        let statusClasses = {
            cancelled: "unvalid",
            prebooked: "in-progress",
            payment_requested: "in-progress",
            paid: "valid",
            free: "valid",
            refunded: "unvalid",
            unbooked: "unvalid",
        };
        return statusClasses[status];
    }

    statusText(status) {
        let statusTexts = {
            cancelled: "cancelled",
            prebooked: "prebooked",
            payment_requested: "payment_requested",
            paid: "paid",
            free: "free",
            refunded: "refunded",
            unbooked: "unbooked",
        };
        return t(statusTexts[status]);
    }

    render() {
        let order = this.props.order;
        return order.tickets_count_valid > 0 &&(
            <div className="basket-item">
                <div className="order-id">
                    <span>{t("Numéro de la commande :")}</span> {order.order_id}
                </div>
                <div className="buying-date">
                    {t("Commande du")}{" "}
                    {format(order.date, "DD.MM.YYYY", { locale: dateLocale })}
                </div>
                {order.events && (
                    <h3>{order.events.map((x) => x.name).join(", ")}</h3>
                )}
                <div className="number-ticket">
                    <span>{order.tickets_count + " "}</span>
                    {i18next.t("billet", {
                        count: order.tickets_count,
                        ns: "basket",
                    })}
                </div>
                {" – "}
                <div className="price-paid">
                    {order.currency}
                    {order.amount}.-
                </div>
                <div
                    className={[
                        "payment-validation",
                        this.statusClass(order.status),
                    ].join(" ")}
                ></div>

                <a
                    href={`${order.tickets_link}`}
                    className="btn-download"
                    target="_blank"
                >
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-4086.503 -166 20.157 35"
                        >
                            <g>
                                <path d="M-4086.503-135.58h20.157v4.58h-20.157z" />
                                <path d="M-4074.042-146.576V-166h-4.58v19.424l-4.766-4.764-3.115 3.298 10.08 10.08 10.077-10.08-3.115-3.115z" />
                            </g>
                        </svg>
                    </span>
                    <span>
                        {i18next.t("Télécharger le billet", {
                            count: order.tickets_count,
                            ns: "user",
                        })}
                    </span>
                </a>
                <a
                    href={`${order.tickets_link_mobile}`}
                    className="btn-download"
                    target="_blank"
                >
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 69 61.3"
                        >
                            <path d="M21.7 34.6c.9.5 1.6 1.2 1.9 2.2l3.4 10c.3 1 1.2 1.2 1.9.4l2.9-3.2c1.3-1.4 3.4-1.7 4.9-.6l10.7 6.9c.9.6 1.8.2 2.1-.8L59 12.2c.3-1-.3-1.5-1.3-1.1L14.6 29.3c-1 .4-1 1.1-.1 1.6l7.2 3.7zM2.1 33.1c-2.9-1.5-2.7-5.7.3-6.9L63.7.3c2.9-1.2 6 1.4 5.2 4.5L55.1 58.5c-.7 2.5-3.6 3.7-5.8 2.3l-12.4-8.1c-.9-.6-2.2-.4-2.9.3l-5 5.6c-2 2.2-5.5 1.4-6.5-1.3l-5-14.7c-.3-1-1.4-2.2-2.3-2.7L2.1 33.1z" />
                        </svg>
                    </span>
                    <span>
                        {i18next.t("Envoyer le billet par mail", {
                            count: order.tickets_count,
                            ns: "user",
                        })}
                    </span>
                </a>
            </div>
        );
    }
}
