import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

import { locale } from "../../app";

export class PaymentProcess extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: true,
            step: undefined,
        };
    }

    resetPayment(orderId) {
        jQuery
            .getJSON(
                `/${locale}/api/payment-process/${orderId}/reset?${Date.now()}`,
            )
            .done((data) => this.fetchCurrentState(orderId))
            .fail((data, textStatus, errorThrown) => {
                this.handleError(data, textStatus, errorThrown);
                this.setState({ isLoading: false });
            });
    }

    handleAjax(data, isFirstLoad) {
        // Reset the payment to method
        if (isFirstLoad && data.step === "form") {
            //console.log("[LOGIC] must reset");
            this.resetPayment(this.props.orderId);
        } else {
            let newState = {
                isLoading: false,
                step: data.step,
            };

            if (data.html) {
                newState["html"] = data.html;
            }

            if (data.url) {
                newState["url"] = data.url;
            }

            //console.log("[XHR] New state", newState);

            this.setState(newState);
        }
    }

    submitForm($form) {
        this.setState({ isLoading: true }, () => {
            $.ajax({
                type: "POST",
                url: $form.attr("action"),
                data: $form.serialize(),
                dataType: "json",
            })
                .done((data) => this.handleAjax(data, false))
                .fail((data, textStatus, errorThrown) => {
                    this.handleError(data, textStatus, errorThrown);
                    this.setState({ isLoading: false });
                });
        });
    }

    handleError(data, textStatus, errorThrown) {
        //console.log("[XHR] Fail", data);
        let errorMessage = i18next.t(
            "Une erreur est survenue, veuillez réessayer plus tard",
            { ns: "default" },
        );
        if (
            data.hasOwnProperty("responseJSON") &&
            data.responseJSON.hasOwnProperty("errors") &&
            data.responseJSON.errors.hasOwnProperty("message")
        ) {
            errorMessage = data.responseJSON.errors.message;
        }

        jQuery(".alert").remove();
        jQuery("<p/>", {
            class: "alert alert-danger",
            text: errorMessage,
        }).prependTo("#container");
    }

    handleSubmitForMethodForm() {
        const onSubmit = ($form) => (e) => {
            e.preventDefault();
            this.submitForm($form);
        };

        const $forms = jQuery("#ajax-form form[method=post]");
        //console.log("[FORMS]", $forms.length);

        $forms.each(function () {
            const $form = $(this);
            const id = $form.attr("id");
            $(this).submit(onSubmit($(this)));
        });
    }

    fetchCurrentState(orderId) {
        return jQuery
            .getJSON(`/${locale}/api/payment-process/${orderId}?${Date.now()}`)
            .done((data) => this.handleAjax(data, true))
            .fail((data, textStatus, errorThrown) => {
                this.handleError(data, textStatus, errorThrown);
                this.setState({ isLoading: false });
            });
    }

    componentDidMount() {
        const { orderId } = this.props;

        window.addEventListener(
            "message",
            function (event) {
                if (event.data === "twint_close_event") {
                    // reload the page
                    window.location.reload();
                } else if (event.data === "twint_payment_success_event") {
                    // go to the /merci page
                    window.location.href = "/merci";
                } else if (event.data === "twint_payment_failed_event") {
                    // display a failure message
                    console.log("twint_payment_failed_event");
                }
            },
            false,
        );

        if (orderId) {
            this.fetchCurrentState(orderId);
        } else {
            this.setState({ isLoading: false });
        }
    }

    componentDidUpdate() {
        const { step, url } = this.state;

        switch (step) {
            case "method":
            case "conditions":
                this.handleSubmitForMethodForm();
                break;
            case "form":
                const $form = jQuery("#ajax-form form[method=post]").first();
                $form.submit();
                break;
            case "thank_you":
                window.location.href = url;
        }
    }

    render() {
        const { isLoading, html, step } = this.state;
        const content = (() => {
            if (isLoading)
                return (
                    <div>{i18next.t("Chargement...", { ns: "default" })}</div>
                );
            else if (html)
                return (
                    <div
                        id="ajax-form"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                );
            else return <div />;
        })();

        return <div>{content}</div>;
    }
}
