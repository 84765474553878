export let line = [
    [46.4884500,	6.7327520],
    [46.4887603,	6.7333314],
    [46.4887898,	6.7333421],		
    [46.4889302,	6.7332241],
    [46.4893143,	6.7344472],
    [46.4896615,	6.7342648],
    [46.4904149,	6.7347797],
    [46.4910132,	6.7344901],
    [46.4911018,	6.7352304],
    [46.4912791,	6.7357883],
    [46.4913087,	6.7364856],
    [46.4917223,	6.7364266],
    [46.4920916,	6.7360672],
    [46.4923538,	6.7360458],
    [46.4926013,	6.7361101],
    [46.4931109,	6.7362389],
    [46.4932217,	6.7359653],
    [46.4933768,	6.7357775],
    [46.4933547,	6.7351123],
    [46.4936353,	6.7344150],
    [46.4937830,	6.7343935],
    [46.4937904,	6.7344150],		
    [46.4946841,	6.7347261],
    [46.4951347,	6.7345115],
    [46.4951495,	6.7345008],		
    [46.4959434,	6.7343881],
    [46.4967226,	6.7345115],
    [46.4961392,	6.7334923],
    [46.4956665,	6.7333743],
    [46.4953563,	6.7330095],
    [46.4951790,	6.7325911],
    [46.4943370,	6.7314002],
    [46.4947580,	6.7308852],
    [46.4952602,	6.7307028],
    [46.4951421,	6.7298606],
    [46.4951864,	6.7295977],
    [46.4953489,	6.7295655],
    [46.4959028,	6.7300376],
    [46.4960948,	6.7302897],
    [46.4962869,	6.7305419],
    [46.4965823,	6.7306599],
    [46.4966045,	6.7311051],
    [46.4967817,	6.7314109],
    [46.4969922,	6.7315557],
    [46.4972322,	6.7315289],
    [46.4960505,	6.7286965],
    [46.4971078,	6.7286624],
    [46.4971521,	6.7283405],
    [46.4981713,	6.7267741],
    [46.4978316,	6.7268814],
    [46.4974328,	6.7254652],
    [46.4975066,	6.7251433],
    [46.4972998,	6.7240168],
    [46.4970930,	6.7228688],
];
