import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

import { Loader } from "../Loader";
import { EditForm } from "./EditForm";
import { PasswordForm } from "./PasswordForm";

export class Forms extends React.Component {
    render() {
        let { form, user, submitForm, updateField } = this.props;
        if (!form || !user)
            return (
                <div>
                    <Loader />
                </div>
            );

        return (
            <div>
                <EditForm
                    form={form}
                    user={user}
                    submitForm={submitForm}
                    updateField={updateField}
                    countries={this.props.countries}
                    civilities={this.props.civilities}
                    languages={this.props.languages}
                    isLoading={this.props.isLoading}
                />
                <PasswordForm
                    submitForm={submitForm}
                    isLoading={this.props.isLoading}
                />
            </div>
        );
    }
}
