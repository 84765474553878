import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

import { PaymentProcess } from "./basket_page/PaymentProcess";
import { BasketContent } from "./basket_page/Basket";
import { Loader } from "./Loader";
import { locale } from "../app";
import { boundQuantity, replaceInArray } from "../shared/utils";

export const t = (key) => i18next.t(key, { ns: "basket" });

export class BasketPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            basket: undefined,
            hasData: false,
            isLoading: false,
            errorMessage: "",
            edited: false,
            userPromotionCode: "",
        };
    }

    __fetchBasket() {
        this.setState({ isLoading: true }, () => {
            jQuery
                .getJSON(`/${locale}/api/basket/?${Date.now()}`)
                .done((data) => {
                    //console.log("[BASKET PAGE XHR] Success", data);
                    this.setState({
                        basket: data,
                        isLoading: false,
                        hasData: true,
                    });
                })
                .fail((err) => {
                    //console.log("[BASKET PAGE XHR] Fail", err);
                    this.setState({ isLoading: false, hasData: false });
                });
        });
    }

    __sendUpdateSeatsToServer(seats) {
        this.setState({ isLoading: true }, () => {
            const { csrfToken, basketUpdateSeatsPath } = this.props.settings;
            const data = {
                _csrf_token: csrfToken,
                seats,
            };

            jQuery
                .post(`${basketUpdateSeatsPath}`, data)
                .done((data) => {
                    //console.log("[BASKET PAGE XHR] Update seats Success", data);
                    this.setState({
                        isLoading: false,
                        basket: data,
                        edited: false,
                    });
                })
                .fail((err) => {
                    //console.log("[BASKET PAGE XHR] Fail", err);
                    this.setState({
                        isLoading: false,
                        errorMessage: t(
                            "Une erreur s'est produite lors de la mise à jour. Veuillez réessayer",
                        ),
                    });
                });
        });
    }

    __onRemoveTicket(categoryId) {
        this.setState({ isLoading: true, errorMessage: "" }, () => {
            const { csrfToken, basketRemovePath } = this.props.settings;

            const data = {
                _csrf_token: csrfToken,
                categoryId,
            };

            jQuery
                .post(`${basketRemovePath}`, data)
                .done((data) => {
                    //console.log("[BASKET PAGE XHR] Removal Success");
                    this.setState({
                        basket: data,
                        isLoading: false,
                        edited: false,
                    });
                })
                .fail((err) => {
                    //console.log("[BASKET PAGE XHR] Fail", err);
                    this.setState({
                        isLoading: false,
                        errorMessage: t(
                            "Une erreur s'est produite à la suppression. Veuillez réessayer",
                        ),
                    });
                });
        });
    }

    __onSubmitPromotionCode(code) {
        this.setState({ isLoading: true, errorMessage: "" }, () => {
            const { csrfToken, basketAddPromotionCodePath } =
                this.props.settings;
            const { userPromotionCode } = this.state;

            const data = {
                _csrf_token: csrfToken,
                code: userPromotionCode,
            };

            jQuery
                .post(`${basketAddPromotionCodePath}`, data)
                .done((data) => {
                    //console.log("[BASKET PAGE XHR] Promocode Success");
                    this.setState({
                        basket: data,
                        isLoading: false,
                        edited: false,
                    });
                })
                .fail((err) => {
                    //console.log("[BASKET PAGE XHR] Fail", err);
                    // dgettext ""
                    this.setState({
                        isLoading: false,
                        errorMessage: t(
                            "Une erreur s'est produite lors de l'ajout du code promotionnel. Veuillez réessayer",
                        ),
                    });
                });
        });
    }

    __buildCategoryIdToSeatObject = ({ items }) => {
        //console.log("items", items);
        return items.reduce((acc, { category_id, seats }) => {
            acc[category_id] = seats;
            return acc;
        }, {});
    };

    __onUpdateSeats = (categoryId, quantity) => {
        if (isNaN(quantity)) {
            // it is mandatory to do setState to trigger rendering, otherwise, Input is not read only
            this.setState();
            return;
        }

        const seats = this.__buildCategoryIdToSeatObject(this.state.basket);
        seats[categoryId] = boundQuantity(quantity);

        this.__sendUpdateSeatsToServer(seats);
    };

    componentDidMount() {
        this.__fetchBasket();
    }

    renderWithContent() {
        const { basket, edited, userPromotionCode } = this.state;
        const { items, total_price } = basket;

        const sendUpdateSeatsToServer = () =>
            this.__sendUpdateSeatsToServer(
                this.__buildCategoryIdToSeatObject(this.state.basket),
            );

        const onRemoveTicket = (categoryId) => {
            const seats = this.__buildCategoryIdToSeatObject(this.state.basket);
            seats[categoryId] = 0;

            this.__sendUpdateSeatsToServer(seats);
        };

        const onUpdatePromotionCode = (value) => {
            this.setState({ userPromotionCode: value });
        };

        if (items.length === 0) {
            return (
                <p>
                    {t(
                        "Vous n'avez rien ajouté dans votre panier pour le moment. Ajouter un ticket et il sera listé ici",
                    )}
                </p>
            );
        } else {
            return (
                <BasketContent
                    edited={edited}
                    basket={basket}
                    userPromotionCode={userPromotionCode}
                    onRemoveTicket={onRemoveTicket}
                    onUpdateSeats={(categoryId, quantity) =>
                        this.__onUpdateSeats(categoryId, quantity)
                    }
                    onSubmitUpdateSeats={sendUpdateSeatsToServer}
                    onUpdatePromotionCode={onUpdatePromotionCode}
                    onSubmitPromotionCode={() => this.__onSubmitPromotionCode()}
                />
            );
        }
    }

    shouldDisplayPaymentProcess = () => {
        const { isLoading, hasData } = this.state;
        return (
            !isLoading &&
            hasData &&
            this.state.basket &&
            this.state.basket.items.length > 0
        );
    };

    render() {
        const { isLoading, hasData, errorMessage } = this.state;

        return (
            <section className="row">
                <div className="left-column">
                    {isLoading ? (
                        <div>
                            <Loader />
                        </div>
                    ) : undefined}
                    {!isLoading && !hasData ? (
                        <div>
                            {i18next.t(
                                "Une erreur s'est produite lors du chargement des informations. Veuillez réessayer",
                                { ns: "default" },
                            )}
                        </div>
                    ) : undefined}
                    {errorMessage ? <p>{errorMessage}</p> : undefined}
                    {hasData ? this.renderWithContent() : undefined}
                </div>
                <div className="right-column">
                    {this.shouldDisplayPaymentProcess() ? (
                        <PaymentProcess orderId={this.props.settings.orderId} />
                    ) : undefined}
                </div>
            </section>
        );
    }
}
