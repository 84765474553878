import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

export const Loader = () => (
    <div className="loader">
        <div className="loader-container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <filter id="goo">
                    <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="10"
                        result="blur"
                    />
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
                    />
                </filter>
            </defs>
        </svg>

        <span className="text-loader">
            {i18next.t("Chargement...", { ns: "default" })}
        </span>
    </div>
);
