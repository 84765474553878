import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import socket from "./../socket";
import { locale } from "../app";
import { Loader } from "./Loader";

const t = (key) => i18next.t(key, { ns: "basket" });

export class Basket extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            items: [],
            isLoading: false,
            active: false,
        };
    }

    joinChannel() {
        var id = window.token;
        if (id) {
            let basket = "basket:" + id;
            let channel = socket.channel(basket, {});
            channel
                .join()
                .receive("ok", (data) => {})
                .receive("error", (resp) => {});
            channel.on("change", (data) => {
                clearTimeout(this.state.hideTimeout);
                clearTimeout(this.state.showTimeout);
                this.setState({
                    items: data.items,
                    isLoading: false,
                    showTimeout: setTimeout(() => this.showActive(), 2500),
                    hideTimeout: setTimeout(() => this.hideActive(), 8500),
                });
            });
        }
    }

    showActive() {
        this.setState({ active: true });
    }

    hideActive() {
        this.setState({ active: false });
    }

    fetchBasket() {
        this.setState({ isLoading: true }, () => {
            jQuery
                .getJSON(`/${locale}/api/basket/?${Date.now()}`)
                .done((data) => {
                    this.setState({ items: data.items, isLoading: false });
                })
                .fail((err) => {
                    this.setState({ isLoading: false });
                });
        });
    }

    onRemoveTicket(e, categoryId) {
        e.preventDefault();
        const seats = this.__buildCategoryIdToSeatObject(this.state.items);
        seats[categoryId] = 0;
        this.__sendUpdateSeatsToServer(seats);
    }

    __buildCategoryIdToSeatObject = (items) => {
        return items.reduce((acc, { category_id, seats }) => {
            acc[category_id] = seats;
            return acc;
        }, {});
    };

    __sendUpdateSeatsToServer(seats) {
        this.setState({ isLoading: true }, () => {
            const data = {
                _csrf_token: window.csrfToken,
                seats,
            };

            jQuery
                .post(`${window.basketUpdateSeatsPath}`, data)
                .done((data) => {
                    this.setState({
                        isLoading: false,
                        items: data.items,
                    });
                })
                .fail((err) => {
                    this.setState({
                        isLoading: false,
                        errorMessage: t(
                            "Une erreur s'est produite lors de la mise à jour. Veuillez réessayer"
                        ),
                    });
                });
        });
    }

    componentDidMount() {
        this.joinChannel();
        this.fetchBasket();
    }

    renderContent() {
        const { items, isLoading } = this.state;
        const isEmpty = items.length === 0;

        if (isLoading) {
            return (
                <div className="content">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="content">
                {isEmpty ? t("Votre panier est vide") : undefined}
                {items.map((item) => (
                    <div className="content-basket">
                        <span className="ticket-number">{item.seats}</span>
                        <span className="name-artist">
                            {item.name.split(" / ").map((artistName) => (
                                <span>{artistName}</span>
                            ))}
                        </span>
                        <a
                            href="#"
                            onClick={(e) =>
                                this.onRemoveTicket(e, item.category_id)
                            }
                            className="delete-trigger"
                        >
                            <span />
                            <span />
                        </a>
                    </div>
                ))}
                {!isEmpty ? (
                    <a
                        href={this.props.basketPath}
                        className="btn-std"
                    >
                        {t("Passer à la caisse")}
                    </a>
                ) : undefined}
            </div>
        );
    }

    render() {
        const { isLoading } = this.state;

        const cardClass = () => {
            let active = this.state.active ? "active-basket" : null;
            return ["card", active].filter((x) => x);
        };

        return (
            <div
                className={cardClass().join(" ")}
                onMouseEnter={() => this.hideActive()}
            >
                <h5 className="card-title">
                    {i18next.t("Panier", { ns: "layout" })}
                </h5>
                {this.renderContent()}
            </div>
        );
    }
}
