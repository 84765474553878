import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { Loader } from "./Loader";

export class ArtistMedias extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            html: "",
            error: false,
        };
    }

    __fetchMedias() {
        this.setState({ isLoading: true }, () => {
            const { path } = this.props;

            return jQuery
                .get(path)
                .done((html) => {
                    //console.log("[XHR] Artist Media Loaded", html);
                    this.setState({ isLoading: false, html });
                })
                .fail((err) => {
                    //console.log("[XHR] Artist Media Fail", err);
                    this.setState({ isLoading: false, error: true });
                });
        });
    }

    componentDidMount() {
        this.__fetchMedias();
    }

    render() {
        const { isLoading, html } = this.state;

        //console.log("html", html)

        return isLoading ? (
            <div>
                <Loader />
            </div>
        ) : (
            <div dangerouslySetInnerHTML={{ __html: html }} />
        );
    }
}
