import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { Link } from "react-router";

import { DayChoice } from "./DayChoice";

export const Filters = ({
    dates,
    venues,
    params,
    updateParam,
    url,
    searchParams,
    filterLimited,
    categoryOffEnable,
}) => {
    const t = (key) => i18next.t(key, { ns: "schedule" });
    return (
        <div id="event-filters">
            <section className="row">
                <div className="filter-title">{t("Festival")}</div>
                <div className="first-line">
                    {!filterLimited && (
                        <div id="event-categories" className="filter-criteria">
                            <ul>
                                <li>
                                    <label htmlFor={`category-in`}>
                                        <input
                                            type="checkbox"
                                            id={`category-in`}
                                            checked={params.categories.in}
                                            onChange={(e) =>
                                                updateParam(
                                                    "in",
                                                    !params.categories.in
                                                )
                                            }
                                        />
                                        <span>{t("In")}</span>
                                    </label>
                                </li>

                                {categoryOffEnable && (
                                    <React.Fragment>
                                        <li>
                                            <label htmlFor={`category-off`}>
                                                <input
                                                    type="checkbox"
                                                    id={`category-off`}
                                                    checked={
                                                        params.categories.off
                                                    }
                                                    onChange={() =>
                                                        updateParam(
                                                            "off",
                                                            !params.categories
                                                                .off
                                                        )
                                                    }
                                                />
                                                <span>{t("Off")}</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor={`category-jazz`}>
                                                <input
                                                    type="checkbox"
                                                    id={`category-jazz`}
                                                    checked={
                                                        params.categories.jazz
                                                    }
                                                    onChange={() =>
                                                        updateParam(
                                                            "jazz",
                                                            !params.categories
                                                                .jazz
                                                        )
                                                    }
                                                />
                                                <span>
                                                    {t("Autour du Jazz")}
                                                </span>
                                            </label>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </div>
                    )}
                    <div id="event-venues" className="filter-criteria" >
                        <div className="select-wrapper" >
                            <select
                                value={params.venue}
                                onChange={(e) =>
                                    updateParam("venue", e.target.value)
                                }
                                style={{minWidth: "175px"}}
                            >

                                    <option value="">
                                        {t("Toutes les salles")}
                                    </option>

                                    {venues
                                        .filter( v => v.visible  == true)
                                        .map(({ id, name }) => (
                                            <option
                                                key={id}
                                                value={id.toString()}
                                            >
                                                {name}
                                            </option>
                                        ))}


                            </select>
                        </div>
                    </div>
                </div>
                <div id="event-dates" className="filter-criteria">
                    <ul>
                        {dates.map((day) => (
                            <DayChoice
                                key={day}
                                checked={day === params.date}
                                updateParam={updateParam}
                                dayString={day}
                            />
                        ))}

                        {searchParams !== "" && (
                            <li>
                                <a href={url} className="reset-search">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 100 100"
                                    >
                                        <path d="M30.5 69.5c-10.8-10.8-10.8-28.3 0-39l.2-.2-.1 7 7 .1.2-18.3-18.9-.9-.3 7 7 .3C12 39.1 12 61 25.5 74.5c5.2 5.2 11.8 8.6 19.1 9.7l3.5.5 1.1-6.9-3.5-.5c-5.8-.9-11.1-3.6-15.2-7.8zm.3-43.7z" />
                                        <path d="M74.4 25.6c-5.4-5.4-12.2-8.8-19.7-9.8l-3.5-.5-.9 6.9 3.5.5c6 .8 11.4 3.5 15.7 7.8 10.8 10.8 10.8 28.3 0 39l-.5.5.1-7.1-7-.1-.2 18.3 18.9.9.3-7-6.9-.3.2-.2c13.5-13.5 13.5-35.4 0-48.9zm-5.5 48.8v-.5l.5.5h-.5z" />
                                    </svg>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </section>
        </div>
    );
};
