import React from "react";
import ReactDOM from "react-dom";
import format from "date-fns/format";

import { EventBox } from "./EventBox";
import { dateLocale } from "../../app";

const EventsForDay = ({ events, showPath, filterLimited }) => {
 
    const hasFree = events.free.length > 0;

    const hasPaid = events.paid.length > 0;
    if (!hasFree && !hasPaid) return;

    const day = hasPaid ? events.paid[0].start_date : events.free[0].start_date;

    return (
        <div>
            {events.paid.length !== 0 && (
                <div className="paid-events">
                    {events.paid.map((event) => (
                        <EventBox key={event.id_airtable} showPath={showPath} event={event} />
                    ))}
                </div>
            )}
            {!filterLimited && events.free.length !== 0 && (
                <div className="free-events">
                    {events.free.map((event) => (
                        <EventBox key={event.id_airtable} showPath={showPath} event={event} />
                    ))}
                </div>
            )}
        </div>
    );
};

export const List = ({ eventsPerDay, dates, showPath, filterLimited }) => {
    return (
        <div id="event-list">
            <section className="row">
                {Object.keys(eventsPerDay).map((date) => (
                    <EventsForDay
                        showPath={showPath}
                        key={date}
                        events={eventsPerDay[date]}
                        filterLimited={filterLimited}
                    />
                ))}
            </section>
        </div>
    );
};

export const ShrinkedList = ({ eventsPerDay, showPath, params }) => {
    const components = [];

    

    Object.keys(eventsPerDay).forEach((date) => {
        const { paid, free } = eventsPerDay[date];

        paid.forEach((event) =>
            components.push(
                <EventBox
                    showPath={showPath}
                    key={event.id_airtable}
                    event={event}
                />
            )
        );
        free.forEach((event) =>
            components.push(
                <EventBox
                    showPath={showPath}
                    key={event.id_airtable}
                    event={event}
                />
            )
        );
    });

    return (
        <div id="event-list">
            <section className="row">
                <div className="event-box-wrapper">
                    <div className="paid-events">{components}</div>
                </div>
            </section>
        </div>
    );
};
