import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import money from "money-math";
import { t } from "../BasketPage";

const computePrice = (items) => {
    return items.reduce((sum, { seats, unit_price }) => {
        const unit = ((unit_price * 100) | 0).toString() + ".00";
        const quantity = (seats | 0).toString() + ".00";

        const unitMoney = money.div(unit, "100.00");

        return money.add(sum, money.mul(unitMoney, quantity));
    }, "0.00");
};

export const formatPrice = (price) => {
    const str = ((price * 100) | 0).toString() + ".00";
    return money.div(str, "100.00");
};

const BasketItem = ({
    item: { seats, name, unit_price, category_id, ticket_name },
    onRemoveTicket,
    onUpdateSeats,
}) => {
    //console.log("seats", seats)
    return (
        <div className="basket-item">
            <h2>
                {name.split(" / ").map((artistName) => (
                    <span>{artistName}</span>
                ))}
            </h2>
            <div className="input-number">
                <span
                    className="input-group-btn"
                    onClick={(e) =>
                        onUpdateSeats(category_id, parseInt(seats - 1))
                    }
                >
                    <button type="button" className="btn-number">
                        <span className="minus" />
                    </button>
                </span>
                <input
                    type="number"
                    className="ticket-number"
                    value={seats}
                    onBlur={(e) =>
                        onUpdateSeats(category_id, parseInt(e.target.value))
                    }
                />
                <span
                    className="input-group-btn"
                    onClick={(e) =>
                        onUpdateSeats(category_id, parseInt(seats + 1))
                    }
                >
                    <button type="button" className="btn-number">
                        <span className="minus plus" />
                    </button>
                </span>
            </div>
            <div className="right-to-input">
                <span className="light-infos">
                    {" "}
                    {i18next.t("billet", { count: seats, ns: "basket" })}{" "}
                    {t("pour le prix de CHF")}
                </span>
                <span className="sum">{formatPrice(unit_price)}</span>
                <span className="light-infos"> {t("par billet")}</span>
            </div>
            <div className="right-to-input">{ticket_name}</div>
            <a
                href="javascript:void(0)"
                onClick={() => onRemoveTicket(category_id)}
                className="delete-trigger"
            >
                <span />
                <span />
            </a>
        </div>
    );
};

export const BasketContent = (props) => {
    const { basket } = props; // data
    const { edited, userPromotionCode } = props; // state
    const {
        onRemoveTicket,
        onUpdateSeats,
        onSubmitUpdateSeats,
        onUpdatePromotionCode,
        onSubmitPromotionCode,
    } = props; // handler

    const { items, total_price, promotion_code } = basket;
    const DISPLAY_PROMOTION_CODE = false;

    return (
        <div>

            <div className="basket-items">
                {items.map((item) => (
                    <BasketItem
                        item={item}
                        onRemoveTicket={onRemoveTicket}
                        onUpdateSeats={onUpdateSeats}
                    />
                ))}
            </div>

            <div className="basket-sum">
                <span>{t("Total")}</span>
                <span className="price">
                    <span className="currency">CHF</span>
                    <span className="sum">
                        {edited
                            ? computePrice(items)
                            : formatPrice(total_price)}
                    </span>
                </span>
            </div>
            {DISPLAY_PROMOTION_CODE ? (
                <div className="basket-promotion-code">
                    <h3>{t("Code promotionnel")}</h3>
                    <p>
                        {t(
                            "Si vous avez un code promotionnel, vous pouvez l'ajouter ici"
                        )}
                    </p>
                    <input
                        type="text"
                        value={userPromotionCode}
                        onInput={(e) => onUpdatePromotionCode(e.target.value)}
                    />
                    <button
                        className="btn-black-to-three"
                        type="button"
                        onClick={() => onSubmitPromotionCode()}
                    >
                        {t("Ajouter")}
                    </button>
                </div>
            ) : undefined}
            {edited ? (
                <p>
                    {t("Vos modifications n'ont pas été sauvegardées.")}
                    <button
                        className="btn-std btn-bg-one"
                        type="button"
                        onClick={() => onSubmitUpdateSeats()}
                    >
                        {t("Mettre à jour le panier")}
                    </button>
                </p>
            ) : undefined}
        </div>
    );
};
