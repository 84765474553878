import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

export const t = (key) => i18next.t(key, { ns: "default" });

export class CookieAlert extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            closed: localStorage.getItem("cookie-alert") === "true",
        };
    }

    accept(e) {
        $.ajax({
            type: "GET",
            url:
                "https://piwik.hawaii.do/index.php?module=API&method=AjaxOptOut.doTrack&format=json",
            jsonp: "callback",
            dataType: "jsonp",
        });
        this.close(e);
    }

    refuse(e) {
        $.ajax({
            type: "GET",
            url:
                "https://piwik.hawaii.do/index.php?module=API&method=AjaxOptOut.doIgnore&format=json",
            jsonp: "callback",
            dataType: "jsonp",
        });
        this.close(e);
    }

    close(e) {
        e.preventDefault();
        localStorage.setItem("cookie-alert", true);
        this.setState({ closed: true });
    }

    render() {
        const { closed } = this.state;
        const { link } = this.props;

        if (closed) {
            return <React.Fragment />;
        }

        return (
            <div className="cookie-alert">

                <div className="cookie-alert-wrapper">
                    <div className="cookie-alert-wrapper-text">
                        <p>
                            {t(
                                "Ce site utilise des cookies pour vous garantir une meilleure expérience sur notre site."
                            )}
                        </p>
                        <p>
                            <a href={link}>{t("Plus d'informations.")}</a>
                        </p>
                    </div>
                    <div className="btn-wrapper">
                        <a
                            className="btn-std"
                            onClick={(e) => this.accept(e)}
                        >
                            {t("Compris")}
                        </a>
                        <a
                            className="btn-std"
                            onClick={(e) => this.refuse(e)}
                        >
                            {t("Ne pas me pister")}
                        </a>
                        <div className="cross" onClick={(e) => this.close(e)}>
                            <span />
                            <span />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
