import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

const t = (key) => i18next.t(key, { ns: "user" });

export class EditForm extends React.Component {
    isMandatory(form, field) {
        if (field === "zipcode") {
            return true;
        }

        return form[field] === "MANDATORY";
    }

    isHidden(form, field) {
        return form[field] === "HIDDEN";
    }

    isDisplay(form, field) {
        return form[field] !== "HIDDEN";
    }

    mandatoryStar(form, field) {
        if (this.isMandatory(form, field)) return <span>*</span>;
    }

    required(form, field) {
        if (this.isMandatory(form, field)) return "required";
    }

    inputField(label, field) {
        let { form, user, updateField } = this.props;
        return (
            <div className="form-group">
                <label for={"update_" + field}>{t(label)}</label>
                {this.mandatoryStar(form, field)}
                <input
                    className="form-control"
                    id={"update_" + field}
                    name={"update[" + field + "]"}
                    value={user[field]}
                    type="text"
                    required={this.isMandatory(form, field)}
                    onChange={(e) => updateField(field, e.target.value)}
                />
            </div>
        );
    }

    selectField(label, field, options) {
        let { form, user, updateField } = this.props;

        const selectOptions = (() => {
            if (Array.isArray(options)) {
                return options.map((label) => [label, label]);
            } else {
                const list = Object.keys(options).map((label) => [
                    label,
                    options[label],
                ]);
                list.sort((a, b) => a[0].localeCompare(b[0]));
                return list;
            }
        })();

        return (
            <div className="form-group form-group-select">
                <label for={"update_" + field}>{t(label)}</label>
                {this.mandatoryStar(form, field)}
                <div className="select-wrapper">
                    <select
                        className="form-control"
                        id={"update_" + field}
                        name={"update[" + field + "]"}
                        defaultValue={user[field]}
                        onChange={(e) => updateField(field, e.target.value)}
                    >
                        {selectOptions.map(([label, value]) => {
                            return <option value={value}>{label}</option>;
                        })}
                    </select>
                </div>
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        const { user } = this.props;
        let data = {
            address: user.address,
            city: user.city,
            civility: user.civility,
            country: user.country,
            email: user.email,
            firm: user.firm,
            firstname: user.firstname,
            language: user.language,
            lastname: user.lastname,
            phone: user.phone,
            zipcode: user.zipcode,
        };

        this.props.submitForm(data);
    }

    render() {
        let { form, user, countries, civilities, languages } = this.props;

        return (
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <div className="form-group-two-column">
                    {this.selectField("Civilité", "civility", civilities)}
                    {this.selectField(
                        "Langue du compte",
                        "language",
                        languages
                    )}
                </div>

                {this.inputField("Prénom", "firstname")}
                {this.inputField("Nom", "lastname")}
                {this.inputField("Adresse email", "email")}
                {this.inputField("Adresse", "address")}

                <div className="form-group-one-fourth">
                    {this.inputField("NPA", "zipcode")}
                    {this.inputField("Ville", "city")}
                </div>

                {this.selectField("Pays", "country", countries)}

                {this.inputField("Téléphone", "phone")}
                <button
                    className="btn-std btn-bg-one"
                    type="submit"
                    disabled={this.props.isLoading}
                >
                    {t("Mettre à jour mes informations")}
                </button>
            </form>
        );
    }
}
