import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";

import format from "date-fns/format";
import parse from "date-fns/parse";
import { dateLocale, locale } from "../../app";

export const EventBox = ({ showPath, event }) => {
    const {
        titles,
        price,
        start_date,
        venueName,
        thumbnail,
        thumbnail_2x,
        is_full,
        custom_fields
    } = event;

    let title_free = null;
    if(custom_fields) {
        title_free = custom_fields.title_free;
    }

    const now = parse(window.currentDate);
    const start = parse(start_date);
    const minute_diff = Math.floor((now.getTime() - start.getTime()) / (1000 * 60));

    let formatedDate = format(start_date, "dddd D MMMM", {
        locale: dateLocale,
    });
    if (locale !== "de") {
        formatedDate = formatedDate.toLowerCase();
    }

    return (
        <div className="event-box">
            <div className="event-box-inner">
                <a
                    href={showPath(event)}
                    className={price === null ? "" : "large"}
                >
                    <div className="event-img">
                        <img
                            src={thumbnail}
                            alt={event.titles.join(" ")}
                            srcSet={`${thumbnail} 1x, ${thumbnail_2x} 2x`}
                        />
                        <div className="overlay" />
                        {is_full != 0 && (
                            <span className="soldout">
                                {i18next.t("Complet", { ns: "basket" })}
                            </span>
                        )}

                        {price != null &&
                            !is_full &&
                            minute_diff > -60 &&
                            minute_diff < 60 && (
                                <span className="soldout">
                                    {i18next.t("Billets sur place", {
                                        ns: "basket",
                                    })}
                                </span>
                            )}
                    </div>
                    <h2>
                        {titles.map((title) => [
                            <span key={title[0]}>
                                <span  dangerouslySetInnerHTML={{ __html: title[0] }}/>
                                {title[1] && (
                                    <span >
                                        {title[1]}
                                    </span>
                                )}
                                {title[2] && (
                                    <span >
                                        {title[2]}
                                    </span>
                                )}
                            </span>,
                        ])}
                    </h2>
                    <div className="event-info">
                        <div className="event-info-up">
                            {/* 8535, special case for balade */}
                            <span className="event-date">{event.id_airtable === 8535 ? i18next.t("Tous les jours") : formatedDate}</span>
                            {event.id_airtable !== 8535 && <span className="event-hour">
                                {format(start_date, "HH:mm", {
                                    locale: dateLocale,
                                })}
                            </span>}
                        </div>
                        <div className="event-info-down">
                            <span className="venue-name">{venueName}</span>
                            {/* recH5SS5ONGs1MKB3 == Le Brunch */}
                            {event.id_airtable !== "recH5SS5ONGs1MKB3" && <span className="event-price">
                                {price
                                    ? `CHF ${price}`
                                    : title_free ? title_free : i18next.t("Gratuit", {
                                          ns: "schedule",
                                      })}
                            </span>}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};
