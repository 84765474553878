import React from "react";
import ReactDOM from "react-dom";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { dateLocale } from "../../app";

/**
 *
 * @param dayString format is YYYY-MM-DD
 */
export const DayChoice = ({ dayString, updateParam, checked }) => {
    const date = parse(dayString);
    const key = `day-${dayString}`;

    const smallDayName = format(date, "dd", { locale: dateLocale });
    const dayOfMonthPadded = format(date, "DD", { locale: dateLocale });
    const monthFull = format(date, "MMMM", { locale: dateLocale });
    return (
        <li>
            <label htmlFor={key}>
                <input
                    type="checkbox"
                    id={key}
                    name={"day-select"}
                    value={dayString}
                    checked={checked}
                    onChange={(e) =>
                        updateParam("date", checked ? "" : e.target.value)
                    }
                />
                <span>
                    <span className="weekday">{smallDayName}</span>{" "}
                    <span className="numberday">{dayOfMonthPadded}</span>{" "}
                    <span className="month">{monthFull}</span>
                </span>
            </label>
        </li>
    );
};
