import React from "react";
import ReactDOM from "react-dom";

import i18next from "i18next";

import { Loader } from "./Loader";
import { Orders } from "./profile/Orders";
import { Forms } from "./profile/Forms";

export class Profile extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { countries, civilities, languages } = window[
            "CullyJazzProfileConfig"
        ];

        this.state = {
            isLoading: false,
            ordersLoading: true,
            orders: null,
            form: null,
            user: null,
            countries: countries,
            civilities: civilities,
            languages: languages,
        };
    }

    componentDidMount() {
        const { ordersPath, userFormPath, userDataPath } = window[
            "CullyJazzProfileConfig"
        ];

        jQuery
            .getJSON(ordersPath)
            .done((data) => {
                this.setState({
                    ordersLoading: false,
                    orders: data,
                });
            })
            .fail((data, textStatus, errorThrown) => {
                this.setState({
                    ordersLoading: false,
                    orders: false,
                });
            });

        jQuery.getJSON(userFormPath).done((data) => {
            this.setState({ form: data });
        });

        jQuery.getJSON(userDataPath).done((data) => {
            this.setState({ user: data });
        });
    }

    submitForm(data) {
        const { updatePath, csrfToken } = window["CullyJazzProfileConfig"];
        this.setState({ isLoading: true });
        jQuery
            .ajax({
                url: updatePath,
                type: "post",
                data: { update: data },
                headers: { "x-csrf-token": csrfToken },
            })
            .done((data) => {
                jQuery(".alert").remove();
                jQuery("<p/>", {
                    class: "alert alert-success",
                    text: data.message,
                }).prependTo("#container");
                this.setState({ isLoading: false });
            })
            .fail((data, textStatus, errorThrown) => {
                let errorMessage = i18next.t(
                    "Impossible de mettre à jour les informations, veuillez réessayer plus tard",
                    { ns: "user" }
                );
                if (
                    data.hasOwnProperty("responseJSON") &&
                    data.responseJSON.hasOwnProperty("errors") &&
                    data.responseJSON.errors.hasOwnProperty("message")
                ) {
                    errorMessage = data.responseJSON.errors.message;
                }

                jQuery(".alert").remove();
                jQuery("<p/>", {
                    class: "alert alert-danger",
                    text: errorMessage,
                }).prependTo("#container");
                this.setState({ isLoading: false });
            });
    }

    updateField(key, value) {
        const { user } = this.state;
        const newUser = Object.assign({}, user, { [key]: value });

        this.setState({ user: newUser });
    }

    render() {
        const {
            form,
            user,
            countries,
            civilities,
            languages,
            orders,
            ordersLoading,
            isLoading,
        } = this.state;

        return (
            <div>
                <div className="left-column">
                    <Forms
                        form={form}
                        user={user}
                        submitForm={(data) => this.submitForm(data)}
                        updateField={(key, value) =>
                            this.updateField(key, value)
                        }
                        countries={countries}
                        civilities={civilities}
                        languages={languages}
                        isLoading={isLoading}
                    />
                </div>
                <div className="right-column">
                    <div className="col">
                        <h2>{i18next.t("Commandes", { ns: "user" })}</h2>
                        {ordersLoading && (
                            <div>
                                <Loader />
                            </div>
                        )}
                        {!ordersLoading && <Orders orders={orders} />}
                    </div>
                </div>
            </div>
        );
    }
}
